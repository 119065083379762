export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    nuxtApp.hook("page:finish", () => {
      // Handle hash navigation after page transitions
      const hash = window.location.hash;
      if (hash) {
        setTimeout(() => {
          const id = hash.slice(1);
          const element = document.getElementById(id);
          if (element) {
            const headerOffset = 80;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition =
              elementPosition + window.scrollY - headerOffset;

            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth",
            });
          }
        }, 300); // Delay to ensure content is fully rendered
      }
    });
  }
});
