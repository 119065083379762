import payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44 from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_DGUO_5WpM0q4n0C1GwzXh5uDVdSA6tvnYC5SxAhiijA from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_89302ae541b3279a246e13b68f4aa874/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_y6Q4lQqqZZSll0NUV1efRHYgKopO0G5K_kcdyiaF7p0 from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_89302ae541b3279a246e13b68f4aa874/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_IXFEvwfuyIC_lsCrzyvwWSp7z4wJS4TsTqnxg5s9l14 from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_89302ae541b3279a246e13b68f4aa874/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_mgyndspZKviC0szi9JMOSn3pFxsts1yjVSUjjJzO3Uw from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_89302ae541b3279a246e13b68f4aa874/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_8_0nOVjbvZr8aQUmB6iU_j0T6tIj1aKj5tT1sbqguQE from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_89302ae541b3279a246e13b68f4aa874/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ZJwGx5FJsgZQ7NrgEtN_LKm5LUCDUmi_BiY34EgA7sg from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_89302ae541b3279a246e13b68f4aa874/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_x8acg8xkI5HiCel_6u_hhiEVbd50XqMwYa2OXQfnZAI from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_89302ae541b3279a246e13b68f4aa874/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_wQZxCd7GncfiA7cATeG6E85ooppel0nGR4fs0S0Q4JA from "/app/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_89302ae541b3279a246e13b68f4aa874/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import anchorNavigation_7XIjk1_4KLWmzI27vrlWVYuJ0n3kcVTO80GTWrQZAkg from "/app/plugins/anchorNavigation.ts";
import vee_validate_tFsTD_xq7Lr2HBLXhmh4D79px5fI_UTbtS_7_2Rkn8g from "/app/plugins/vee-validate.ts";
import auth_ydS_uAQ5qS4E2Ez9vBX0d0JPoo00WYZNOgh9_nraCUo from "/app/plugins/auth.ts";
import cookies_sisHiBOjzAv5IPvFgVIiOxXz4TLhr96GevWdTx4s_U4 from "/app/plugins/cookies.ts";
import customFetch_LmE1rkOvXwPQnHwxptXRvFzYG2d9JrTITcM5rmisUJM from "/app/plugins/customFetch.ts";
import vue3_toastify_tU4V_Q_3gRw3rPPdj6Y_FGU4jrEWuLb9DIUytSnZjGI from "/app/plugins/vue3-toastify.ts";
import vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds from "/app/plugins/vuetify.ts";
export default [
  payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44,
  revive_payload_client_DGUO_5WpM0q4n0C1GwzXh5uDVdSA6tvnYC5SxAhiijA,
  unhead_y6Q4lQqqZZSll0NUV1efRHYgKopO0G5K_kcdyiaF7p0,
  router_IXFEvwfuyIC_lsCrzyvwWSp7z4wJS4TsTqnxg5s9l14,
  payload_client_mgyndspZKviC0szi9JMOSn3pFxsts1yjVSUjjJzO3Uw,
  navigation_repaint_client_8_0nOVjbvZr8aQUmB6iU_j0T6tIj1aKj5tT1sbqguQE,
  check_outdated_build_client_ZJwGx5FJsgZQ7NrgEtN_LKm5LUCDUmi_BiY34EgA7sg,
  chunk_reload_client_x8acg8xkI5HiCel_6u_hhiEVbd50XqMwYa2OXQfnZAI,
  plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_wQZxCd7GncfiA7cATeG6E85ooppel0nGR4fs0S0Q4JA,
  anchorNavigation_7XIjk1_4KLWmzI27vrlWVYuJ0n3kcVTO80GTWrQZAkg,
  vee_validate_tFsTD_xq7Lr2HBLXhmh4D79px5fI_UTbtS_7_2Rkn8g,
  auth_ydS_uAQ5qS4E2Ez9vBX0d0JPoo00WYZNOgh9_nraCUo,
  cookies_sisHiBOjzAv5IPvFgVIiOxXz4TLhr96GevWdTx4s_U4,
  customFetch_LmE1rkOvXwPQnHwxptXRvFzYG2d9JrTITcM5rmisUJM,
  vue3_toastify_tU4V_Q_3gRw3rPPdj6Y_FGU4jrEWuLb9DIUytSnZjGI,
  vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds
]