export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      cookies: {
        getToken: (): string => {
          if (import.meta.client) {
            const value = getDirectCookieValue("auth_token"); // Use actual cookie name
            console.log("Cookie plugin getToken:", value);
            return value || "";
          }
          return "";
        },

        setToken: (token: string): void => {
          if (import.meta.client) {
            // Use explicit parameters for better cookie control
            document.cookie = `auth_token=${token}; path=/; max-age=604800; SameSite=Lax`;
            // console.log("Set token cookie:", token);
          }
        },

        removeToken: (): void => {
          if (import.meta.client) {
            document.cookie =
              "auth_token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;";
            console.log("Removed token cookie");
          }
        },
      },
    },
  };
});
