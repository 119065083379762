// middleware/authenticated.global.ts
import { useAuthStore } from "~/common/stores/AuthStore";
import { storeToRefs } from "pinia";

export default defineNuxtRouteMiddleware(async (to) => {
  const authStore = useAuthStore();
  const { isAuthenticated } = storeToRefs(authStore);

  // Different behavior for server vs client
  if (import.meta.server) {
    // SERVER-SIDE: Be permissive with dashboard routes during SSR
    // Let the page render on the server, client will handle actual auth
    if (to.path.startsWith("/dashboard")) {
      // Don't redirect during SSR for dashboard routes
      // We'll validate auth on client after hydration
      return;
    }

    // SERVER-SIDE: For auth page, only redirect if definitely authenticated
    if (to.path === "/auth" && isAuthenticated.value) {
      return navigateTo("/");
    }

    return; // Allow SSR to proceed for all routes
  }

  // CLIENT-SIDE HANDLING
  try {
    const cookies = document.cookie.split(";");
    const tokenCookie = cookies.find((c) => c.trim().startsWith("auth_token="));
    const hasTokenInCookies = !!tokenCookie;

    if (hasTokenInCookies && !authStore.authTokens) {
      const token = tokenCookie.trim().substring("auth_token=".length);
      authStore.authTokens = `Bearer ${token}`;
    }

    if (hasTokenInCookies || isAuthenticated.value) {
      if (!authStore.user.name && authStore.status === "idle") {
        await authStore.fetchUser();
      }
    }

    if (to.path === "/auth") {
      if (hasTokenInCookies && isAuthenticated.value) {
        return navigateTo("/");
      }
    }

    if (to.path.startsWith("/dashboard")) {
      if (!hasTokenInCookies && !isAuthenticated.value) {
        return navigateTo("/auth");
      }
    }
  } catch (error) {
    console.error("Auth middleware error:", error);
  }
});
