import "@mdi/font/css/materialdesignicons.css";
import { VFileUpload } from "vuetify/labs/VFileUpload";

import "vuetify/styles";
import { createVuetify } from "vuetify";

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    components: {
      VFileUpload,
    },
    theme: {
      themes: {
        light: {
          colors: {
            primary: "#41a828",
          },
        },
      },
    },
  });
  nuxtApp.vueApp.use(vuetify);
});
