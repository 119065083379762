export function getDirectCookieValue(name: string): string | null {
  if (!import.meta.client) return null;

  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      // console.log("Found cookie:", name, cookie.substring(name.length + 1));
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}
