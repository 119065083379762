<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss">
.primary--text {
  color: #2e7d32 !important;
}

.bg-hero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.multiline-text {
  white-space: pre-line; /* This preserves line breaks and wraps text */
  word-break: break-word; /* Ensures long words don't overflow */
}

.v-file-upload--density-comfortable {
  padding: 16px !important;
  .v-file-upload-icon {
    font-size: 2rem !important;
  }
  .v-file-upload-title {
    font-size: 1rem !important;
  }
  .v-file-upload-divider {
    margin: 16px 0 !important;
  }
}

.min-h-screen {
  min-height: calc(100vh - 465px);
}

.v-file-upload--density-comfortable.bg-primary {
  background-color: #fff !important;
  color: #333 !important;
  border-color: #41a828 !important;
}
</style>
