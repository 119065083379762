import { defineRule } from "vee-validate";
import {
  min_value as minValue,
  required,
  alpha_spaces as alphaSpace,
  email,
  max,
  confirmed,
  min,
  numeric,
} from "@vee-validate/rules";

interface FieldValidationMetaInfo {
  field: string;
  name: string;
  label?: string;
  value: unknown;
  form: Record<string, unknown>;
  rule?: {
    name: string;
    params?: Record<string, unknown> | unknown[];
  };
}

export default defineNuxtPlugin(() => {
  defineRule("required", (value, _arg, ctx) => {
    return required(value) || `Kolom ${ctx.label || ctx.field} harus diisi`;
  });

  defineRule("password", (value: string) => {
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,20}$/;
    return (
      passwordRegex.test(value) ||
      "Password harus mengandung minimal 8 karakter, 1 huruf besar, 1 huruf kecil, 1 angka dan 1 karakter spesial"
    );
  });

  defineRule("min", (value: string, args: [number]) => {
    return min(value, args) || `Kolom harus minimal ${args[0]} karakter`;
  });

  defineRule("confirmed", (value: string, args: [string]) => {
    return (
      confirmed(value, args) ||
      "Kolom konfirmasi tidak sama dengan kolom sebelumnya"
    );
  });

  defineRule("email", (value: string) => {
    return email(value) || "Format alamat email tidak valid";
  });

  defineRule("min_value", (value: string, args: [number]) => {
    return minValue(value, args) || `Kolom harus minimal ${args[0]}`;
  });

  defineRule("max", (value: string, args: [number]) => {
    return max(value, args) || `Kolom maksimal ${args[0]} karakter`;
  });

  defineRule("numeric", (value: string) => {
    return numeric(value) || "Kolom hanya boleh berisi angka";
  });

  defineRule(
    "alpha_spaces",
    (value: string, args: string[], ctx: FieldValidationMetaInfo) => {
      return (
        alphaSpace(value, { locale: args[0] }) ||
        `Kolom ${ctx.label || ctx.field} hanya boleh berisi karakter alfabet dan spasi`
      );
    },
  );

  defineRule("phone", (value: string) => {
    // Regex to match valid phone numbers with at least 10 digits and no whitespace
    const phoneRegex = /^\+?(\d[\d\-,.]*)$/;

    // Check if the phone number matches the regex and has at least 10 digits
    if (phoneRegex.test(value)) {
      // Remove non-digit characters and check the length of the remaining digits
      const digitsOnly = value.replace(/\D/g, "");
      return digitsOnly.length >= 10 || "Nomor telepon harus minimal 10 digit";
    }

    return "Format nomor telepon tidak valid";
  });

  // Date validation
  defineRule("date_format", (value: string) => {
    if (!value) return true;

    const date = new Date(value);
    return !isNaN(date.getTime()) || "Format tanggal tidak valid";
  });

  // Date comparison rules
  defineRule(
    "after",
    (value: string, [target]: [string], ctx: FieldValidationMetaInfo) => {
      if (!value || !ctx.form[target]) return true;

      const date1 = new Date(value);
      const date2 = new Date(ctx.form[target] as string);

      return date1 >= date2 || `Tanggal harus setelah ${target}`;
    },
  );

  defineRule(
    "before",
    (value: string, [target]: [string], ctx: FieldValidationMetaInfo) => {
      if (!value || !ctx.form[target]) return true;

      const date1 = new Date(value);
      const date2 = new Date(ctx.form[target] as string);

      return date1 <= date2 || `Tanggal harus sebelum ${target}`;
    },
  );

  // Validate a number is positive
  defineRule("positive", (value: unknown) => {
    if (value === null || value === undefined || value === "") return true;

    const numValue = Number(value);
    return (!isNaN(numValue) && numValue > 0) || "Nilai harus lebih dari 0";
  });

  // File size validation
  defineRule("max_size", (value: any, [size]: [number]) => {
    if (!value) return true;

    // For FileList or array of Files
    if (value instanceof FileList || Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        const file = value[i];
        if (file.size > size * 1024 * 1024) {
          return `File "${file.name}" melebihi batas ukuran ${size}MB`;
        }
      }
      return true;
    }

    // For single File
    if (value instanceof File) {
      return (
        value.size <= size * 1024 * 1024 ||
        `File melebihi batas ukuran ${size}MB`
      );
    }

    return true;
  });

  // File type validation
  defineRule("file_type", (value: any, [types]: [string]) => {
    if (!value) return true;

    const allowedTypes = types.split(",").map((type) => type.trim());

    // For FileList or array of Files
    if (value instanceof FileList || Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        const file = value[i];
        const fileExt = file.name.split(".").pop()?.toLowerCase();
        if (!fileExt || !allowedTypes.includes(fileExt)) {
          return `File "${file.name}" harus berformat ${types}`;
        }
      }
      return true;
    }

    // For single File
    if (value instanceof File) {
      const fileExt = value.name.split(".").pop()?.toLowerCase();
      return (
        (fileExt && allowedTypes.includes(fileExt)) ||
        `File harus berformat ${types}`
      );
    }

    return true;
  });
});
