import { defineStore } from "pinia";

const initialUserState = (): UserProfile => ({
  name: "",
  email: "",
  birthDate: "",
  phone: "",
  gender: "MALE" as Gender,
  avatar: "",
  avatarUrl: "",
  aboutMe: "",
  occupation: "",
  interest: [],
  resume: "",
  resumeUrl: "",
  address: "",
  province: null,
  city: null,
  district: null,
  village: null,
  isSuperadmin: false,
  postalCode: "",
  verifiedStatus: "NON_VERIFIED",
  completionPercentage: 0,
  userStats: {
    activitiesJoined: 0,
    rating: 0,
    totalWorkHours: 0,
  },
  createdAt: "",
});

const generateMockUser = (): UserProfile => ({
  name: "Mock User",
  email: "test@mail.com",
  birthDate: "1990-01-01",
  phone: "081234567890",
  gender: "MALE",
  avatar: "",
  avatarUrl: "",
  aboutMe: "",
  occupation: "",
  interest: [],
  resume: "",
  resumeUrl: "",
  address: "",
  province: null,
  city: null,
  district: null,
  village: null,
  postalCode: "",
  isSuperadmin: false,
  verifiedStatus: "NON_VERIFIED",
  completionPercentage: 0,
  userStats: {
    activitiesJoined: 0,
    rating: 0,
    totalWorkHours: 0,
  },
  createdAt: "",
});

export const useAuthStore = defineStore("auth", () => {
  // State
  const status = ref<string>("idle");
  const user = ref<UserProfile>(initialUserState());
  const authTokens = ref<string>("");

  // Nuxt instance for accessing plugins
  const nuxtApp = useNuxtApp();

  // Computed properties
  const isAuthenticated = computed(() => !!authTokens.value);

  // Actions
  const resetAuthStore = (): void => {
    status.value = "idle";
    user.value = initialUserState();
    authTokens.value = "";

    // Use the plugin for cookies
    if (import.meta.client && nuxtApp.$cookies) {
      nuxtApp.$cookies.removeToken();
    }
  };

  const fetchUser = async (): Promise<void> => {
    // Guard against calling when not authenticated
    if (!isAuthenticated.value) {
      return;
    }

    status.value = "pending";

    try {
      const useMockData = useRuntimeConfig().public.useMockData;
      if (useMockData) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        user.value = generateMockUser();
        status.value = "resolved";
        return;
      }

      const { data }: { data: UserProfile } =
        await nuxtApp.$customFetch("/profiles");
      user.value = data;
      status.value = "resolved";
    } catch (error: unknown) {
      status.value = "rejected";
      resetAuthStore();
      throw error;
    }
  };

  const signOut = (): void => {
    const route = useRoute();
    if (route.path.startsWith("/dashboard")) {
      navigateTo("/auth");
    }
    resetAuthStore();
  };

  const signIn = async (email: string, password: string): Promise<void> => {
    if (!email || !password) throw new Error("email and password are required");

    try {
      const useMockData = useRuntimeConfig().public.useMockData;
      if (useMockData) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        authTokens.value = `Bearer mock-token`;
        if (import.meta.client && nuxtApp.$cookies) {
          nuxtApp.$cookies.setToken("mock-token");
        }
        return;
      }

      const { data }: { data: { token: string } } = await nuxtApp.$customFetch(
        "/auth/sign-in",
        {
          method: "POST",
          body: {
            email,
            password,
          },
        },
      );

      authTokens.value = `Bearer ${data.token}`;
      if (import.meta.client && nuxtApp.$cookies) {
        nuxtApp.$cookies.setToken(data.token);
      }
    } catch (error: unknown) {
      throw error;
    }
  };

  const signUp = async ({
    name,
    email,
    phone,
    password,
  }: {
    name: string;
    email: string;
    phone: string;
    password: string;
  }): Promise<void> => {
    if (!email || !password) throw new Error("email and password are required");

    try {
      const useMockData = useRuntimeConfig().public.useMockData;
      if (useMockData) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await signIn(email, password);
        return;
      }

      await nuxtApp.$customFetch("/auth/sign-up", {
        method: "POST",
        body: {
          name,
          email,
          phone,
          password,
        },
      });
      await signIn(email, password);
    } catch (error: unknown) {
      throw error;
    }
  };

  const setTokenToCookies = (accessToken: string): void => {
    if (import.meta.client && nuxtApp.$cookies) {
      nuxtApp.$cookies.setToken(accessToken);
    }
  };

  return {
    // State
    user,
    authTokens,
    status,

    // Computed
    isAuthenticated,
    isSuperAdmin: computed(() => user.value.isSuperadmin),
    isVerified: computed(() => user.value.verifiedStatus === "VERIFIED"),

    // Actions
    signIn,
    signUp,
    signOut,
    resetAuthStore,
    fetchUser,
    setTokenToCookies,
  };
});
