
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_93q0MUESNGWO_45HEv_y3s_45JLFqlTZyi_45xyMJd8zDt3QmpgMeta } from "/app/pages/activities/[slug].vue?macro=true";
import { default as index3IAlOUPjudokwLk5fHMNX_2ErptX4undnQbtukBLvLEMeta } from "/app/pages/activities/index.vue?macro=true";
import { default as authLldLAL6sjaJfinhufTblQ2SvXbV4tXp__siTwYISsSIMeta } from "/app/pages/auth.vue?macro=true";
import { default as formwPYOCSJJyn9PTueljwbcy_45Y7KFWJ5A2INxiIXRMXiI0Meta } from "/app/pages/dashboard/activities/form.vue?macro=true";
import { default as indexpba0GVeomWMykoOF6_45_45SjZ_45gJI7rbtZg7nv84QcggGkMeta } from "/app/pages/dashboard/activities/index.vue?macro=true";
import { default as _91slug_93VUhG6rXXKT554zLCZ9iRNHOieKMZU_45QBNb0ztkSxkSMMeta } from "/app/pages/dashboard/activities/manage/[slug].vue?macro=true";
import { default as activitiesFUF0yQ9WGhwK8rOrRiJZ_fzZx0fTGMD5YeIfscQSUyIMeta } from "/app/pages/dashboard/admin/activities.vue?macro=true";
import { default as usersTXE9QQ5JjBQewK_l_45Qkm_pdMBXO9fC7fTopt_45BEjtjsMeta } from "/app/pages/dashboard/admin/users.vue?macro=true";
import { default as change_45passwordPD3OCsYHtlsVShl5ivQo_45koKNItRjGcNAl8mtIduHjcMeta } from "/app/pages/dashboard/change-password.vue?macro=true";
import { default as my_45activitieskRp5zpdI8X8yvXc20nM1odrHT7Nlx9IMnfYYufCx_jQMeta } from "/app/pages/dashboard/my-activities.vue?macro=true";
import { default as editFSptKIoZ1lCj0agCWeFSYoPeSUkzZ_45i1B_mzoQu9IJUMeta } from "/app/pages/dashboard/profile/edit.vue?macro=true";
import { default as indexHeDrCakyvPzB2ImbrGV3LJeGutMVsWJFwVFMQN0CYG4Meta } from "/app/pages/dashboard/profile/index.vue?macro=true";
import { default as dashboardMP4s_45_45Pnrs3lAogdEWxQSVgBglFzEw4XrIvkluYCTzEMeta } from "/app/pages/dashboard.vue?macro=true";
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: "activities-slug",
    path: "/activities/:slug()",
    meta: _91slug_93q0MUESNGWO_45HEv_y3s_45JLFqlTZyi_45xyMJd8zDt3QmpgMeta || {},
    component: () => import("/app/pages/activities/[slug].vue")
  },
  {
    name: "activities",
    path: "/activities",
    meta: index3IAlOUPjudokwLk5fHMNX_2ErptX4undnQbtukBLvLEMeta || {},
    component: () => import("/app/pages/activities/index.vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: authLldLAL6sjaJfinhufTblQ2SvXbV4tXp__siTwYISsSIMeta || {},
    component: () => import("/app/pages/auth.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/app/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-activities-form",
    path: "activities/form",
    component: () => import("/app/pages/dashboard/activities/form.vue")
  },
  {
    name: "dashboard-activities",
    path: "activities",
    component: () => import("/app/pages/dashboard/activities/index.vue")
  },
  {
    name: "dashboard-activities-manage-slug",
    path: "activities/manage/:slug()",
    component: () => import("/app/pages/dashboard/activities/manage/[slug].vue")
  },
  {
    name: "dashboard-admin-activities",
    path: "admin/activities",
    component: () => import("/app/pages/dashboard/admin/activities.vue")
  },
  {
    name: "dashboard-admin-users",
    path: "admin/users",
    component: () => import("/app/pages/dashboard/admin/users.vue")
  },
  {
    name: "dashboard-change-password",
    path: "change-password",
    component: () => import("/app/pages/dashboard/change-password.vue")
  },
  {
    name: "dashboard-my-activities",
    path: "my-activities",
    component: () => import("/app/pages/dashboard/my-activities.vue")
  },
  {
    name: "dashboard-profile-edit",
    path: "profile/edit",
    component: () => import("/app/pages/dashboard/profile/edit.vue")
  },
  {
    name: "dashboard-profile",
    path: "profile",
    component: () => import("/app/pages/dashboard/profile/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta || {},
    component: () => import("/app/pages/index.vue")
  }
]